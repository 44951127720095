import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { css } from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { theme } from 'src/styles/styled-components';
import MDXComponents from 'src/components/mdx-components';
import { Section } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Logo } from 'src/containers/Logo.new';
import { Link } from 'src/containers/Link';
import { Icon } from 'src/components/atoms/Icon';
import { InlineList } from 'src/components/molecules/InlineList';
import { HubspotForm } from 'src/components/atoms/HubspotForm';
import { Box } from 'src/components/atoms/Box';

export const pageQuery = graphql`
  query {
    amex: file(relativePath: { eq: "amex_logo.png" }) {
      ...LogoImageDataNoSharp
    }
    adobe: file(relativePath: { eq: "adobe_logo.svg" }) {
      ...LogoImageDataNoSharp
    }
    wellmark: file(relativePath: { eq: "wellmark_logo.png" }) {
      ...LogoImageDataNoSharp
    }
    dell: file(relativePath: { eq: "dell_logo.png" }) {
      ...LogoImageDataNoSharp
    }
    homedepot: file(relativePath: { eq: "homedepot_logo.png" }) {
      ...LogoImageDataNoSharp
    }
    orange: file(relativePath: { eq: "orange_logo.svg" }) {
      ...LogoImageDataNoSharp
    }
    helvetia: file(relativePath: { eq: "helvetia_logo.svg" }) {
      ...LogoImageDataNoSharp
    }
    tmobile: file(relativePath: { eq: "telekom_logo.png" }) {
      ...LogoImageDataNoSharp
    }
  }
`;

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsHubspotForm');
  customData = JSON.parse(customData);

  const data = useStaticQuery(pageQuery);

  return (
    <MDXProvider components={MDXComponents}>
      <Section {...customData?.flags}>
        {!!heading && <h2>{heading}</h2>}
        {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        {callouts.map(({ id, heading, contentNode, formId, sfCampaignId }) => (
          <Grid key={id} verticalAlign="center" spacious {...customData?.gridProps}>
            <Column>
              <Box spacious>
                <HubspotForm
                  customStyles={customData.hubspotFormStyles}
                  formId={formId}
                  sfdcCampaignId={sfCampaignId}
                />
              </Box>
            </Column>
            <Column
              css={css`
                hyphens: none;
              `}
            >
            <div
              css={css`
                padding-bottom: 40%;
                @media (max-width: ${theme.smallBreakpoint}) {
                  display: none;
                }
              `}
            />
              {!!heading && <h2>{heading}</h2>}
              <div dangerouslySetInnerHTML={{ __html: contentNode.childMarkdownRemark.html }}></div>
              {customData.customer_logos && customData.customer_logos.length > 0 && (
                <Section noPadding style={{padding: '0px'}}>
                  <InlineList noMargin>
                    {customData.customer_logos.map((item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.link_path}>
                            <Logo alt={item.alt_text} data={data[item.image_key]} white />
                          </Link>
                        </li>
                      )
                    })}
                  </InlineList>
                </Section>
              )}
            </Column>
          </Grid>
        ))}
        {ctas.length > 0 && (
          <Section primary>
            <Grid>
              {ctas.map((cta, index) => {
                const ctaCustomData = JSON.parse(cta.customData);
                return (
                  <Column key={index}>
                    <Grid columns="max-content 1fr" verticalAlign="center">
                    <Column>
                      {ctaCustomData?.icon && <Icon icon={ctaCustomData?.icon} size="3x" circle />}
                    </Column>
                    <Column>
                      {!!cta.heading && (
                        <h3>
                          {!!cta.url && <a href={cta.url}>{cta.heading}</a>}
                          {!cta.url && cta.heading}
                        </h3>
                      )}
                      <div
                        dangerouslySetInnerHTML={{__html: cta.text}}
                      />
                    </Column>
                    </Grid>
                  </Column>
                )
              })}
            </Grid>
          </Section>
        )}
      </Section>
    </MDXProvider>
  );
}
